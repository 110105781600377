import React from "react"
import { StaticQuery, graphql } from "gatsby"

import GalleryList from "./GalleryList"

const LandingPages = () => {
  const imageDescription = {
    landingPage1: {
      title: "Wallet Optimizer",
      type: "Landing Page",
      url: "/work/landing-pages/wallet-optimizer",
    },
    landingPage2: {
      title: "Coming Soon",
      type: "Landing Page",
      url: "/work",
    },
    landingPage3: {
      title: "Coming Soon",
      type: "Landing Page",
      url: "/work",
    },
  }

  return (
    <StaticQuery
      query={graphql`
        fragment fluidImage on File {
          childImageSharp {
            gatsbyImageData(width: 600, layout: CONSTRAINED)
          }
        }

        {
          landingPage1: file(
            relativePath: { eq: "work/landing/wallet-optimizer.png" }
          ) {
            ...fluidImage
          }
          landingPage2: file(
            relativePath: { eq: "work/landing/coming-soon.jpg" }
          ) {
            ...fluidImage
          }
          landingPage3: file(
            relativePath: { eq: "work/landing/coming-soon.jpg" }
          ) {
            ...fluidImage
          }
        }
      `}
      render={(data) => (
        <GalleryList imageData={data} imageDescription={imageDescription} />
      )}
    />
  )
}

export default LandingPages
