import React, { useEffect } from "react"

import Websites from "./Websites"
import LandingPages from "./LandingPages"
import WebApps from "./WebApps"
import MobileApps from "./MobileApps"

const WorkPageExperiences = ({ locationState }) => {
  const [btnActive, setBtnActive] = React.useState("Websites")

  useEffect(() => {
    locationState?.tab && setBtnActive(locationState?.tab)
  }, [locationState])

  let gallery

  switch (btnActive) {
    case "Websites":
      gallery = <Websites />
      break
    case "LandingPages":
      gallery = <LandingPages />
      break
    case "Web":
      gallery = <WebApps />
      break
    case "Mobile":
      gallery = <MobileApps />
      break
    default:
      gallery = <Websites />
      break
  }

  const navigation = (
    <div className="row mt-5 mb-5">
      <div className="workFilterButtons col-md-12 text-center">
        <div className="wrap">
          <button
            className={btnActive === "Websites" ? "active" : null}
            onClick={() => setBtnActive("Websites")}
          >
            Websites
          </button>
          <button
            className={btnActive === "LandingPages" ? "active" : null}
            onClick={() => setBtnActive("LandingPages")}
          >
            Landing Pages
          </button>
          <button
            className={
              btnActive === "Web"
                ? "active d-inline-block d-md-none"
                : "d-inline-block d-md-none"
            }
            onClick={() => setBtnActive("Web")}
          >
            Web Apps
          </button>
          <button
            className={
              btnActive === "Web"
                ? "active d-none d-md-inline-block"
                : "d-none d-md-inline-block"
            }
            onClick={() => setBtnActive("Web")}
          >
            Web Applications
          </button>
          <button
            className={
              btnActive === "Mobile"
                ? "active d-inline-block d-md-none"
                : "d-inline-block d-md-none"
            }
            onClick={() => setBtnActive("Mobile")}
          >
            Mobile Apps
          </button>
          <button
            className={
              btnActive === "Mobile"
                ? "active d-none d-md-inline-block"
                : "d-none d-md-inline-block"
            }
            onClick={() => setBtnActive("Mobile")}
          >
            Mobile Applications
          </button>
        </div>
      </div>
    </div>
  )

  return (
    <section id="workExperience" className="mt-3">
      <div className="container mb-5">
        <div className="row">
          <div className="col-lg-9 m-auto text-center ">
            <h2 className="text-capitalize">Case Studies</h2>
            <p className="text-muted fw-l">
              Below you'll find multiple ideas we've turned into digital
              experiences with our clients. Choose a project to see the detailed
              case study.
            </p>
          </div>
        </div>
        {navigation}
        {gallery}
      </div>
    </section>
  )
}

export default WorkPageExperiences
