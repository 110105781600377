import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PageHeader from "../components/common/PageHeader"
import CaseStudies from "../components/pages/work/CaseStudies"
import Contact from "../components/pages/index/Contact"

const WorkPage = ({ location }) => (
  <StaticQuery
    query={graphql`
      {
        work: file(relativePath: { eq: "work.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    `}
    render={(data) => (
      <Layout>
        <Seo
          title="Work"
          keywords={[`work`, `portfolio`, `case studies`]}
          description="View our portfolio of case studies to see how we've teamed up with our clients to turn their ideas into digital experiences."
        />
        <PageHeader
          image={data.work.childImageSharp.gatsbyImageData}
          title="Our Work"
          subTitle="See the experiences we've created with our clients"
        />
        <main>
          <CaseStudies locationState={location.state} />
          <Contact heading="Need a custom built application?" />
        </main>
      </Layout>
    )}
  />
)

export default WorkPage
