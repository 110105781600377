import React from "react"
import { StaticQuery, graphql } from "gatsby"

import GalleryList from "./GalleryList"

const Websites = () => {
  const imageDescription = {
    website1: {
      title: "Beach Hop",
      type: "Website",
      url: "/work/websites/beachhop",
    },
    website2: {
      title: "Foodel",
      type: "Website",
      url: "/work/websites/foodel",
    },
    website3: {
      title: "Tackis",
      type: "Website",
      url: "/work/websites/tackis",
    },
  }

  return (
    <StaticQuery
      query={graphql`fragment fluidImage on File {
  childImageSharp {
    gatsbyImageData(width: 600, layout: CONSTRAINED)
  }
}

{
  website1: file(relativePath: {eq: "work/websites/beach-hop-project.jpg"}) {
    ...fluidImage
  }
  website2: file(relativePath: {eq: "work/websites/foodel-project.jpg"}) {
    ...fluidImage
  }
  website3: file(relativePath: {eq: "work/websites/tackis-project.jpg"}) {
    ...fluidImage
  }
}
`}
      render={data => (
        <GalleryList imageData={data} imageDescription={imageDescription} />
      )}
    />
  );
}

export default Websites
