import React from "react"
import { StaticQuery, graphql } from "gatsby"

import GalleryList from "./GalleryList"

const WebApps = () => {
  const imageDescription = {
    webApp1: {
      title: "Graphic Color",
      type: "Web Development",
      url: "/work/web-applications/graphic-color",
    },
    webApp2: {
      title: "DevLink",
      type: "Web Development",
      url: "/work/web-applications/devlink",
    },
    webApp3: {
      title: "WFH Lister",
      type: "Web Development",
      url: "/work/web-applications/wfh-lister",
    },
  }

  return (
    <StaticQuery
      query={graphql`fragment fluidImage on File {
  childImageSharp {
    gatsbyImageData(width: 600, layout: CONSTRAINED)
  }
}

{
  webApp1: file(relativePath: {eq: "work/web/graphic-color.jpg"}) {
    ...fluidImage
  }
  webApp2: file(relativePath: {eq: "work/web/devlink-project.jpeg"}) {
    ...fluidImage
  }
  webApp3: file(relativePath: {eq: "work/web/wfh-lister.jpg"}) {
    ...fluidImage
  }
}
`}
      render={data => (
        <GalleryList imageData={data} imageDescription={imageDescription} />
      )}
    />
  );
}

export default WebApps
