import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import Slider from "react-slick"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const GalleryList = ({ imageData, imageDescription }) => {
  let galleryItemsMD = []
  let galleryItemsSM = []

  const slickSettings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  }

  for (let key in imageData) {
    galleryItemsMD.push(
      <div key={key} className="col-md-6 col-lg-4 mb-5">
        <Link to={imageDescription[key].url}>
          <div className="position-relative">
            <GatsbyImage
              image={imageData[key].childImageSharp.gatsbyImageData}
              alt={imageDescription[key].title}
              style={{
                borderRadius: "3px",
                boxShadow: "0 1rem 1rem rgba(0,0,0, 0.2)",
              }}
            />
            <div className="latest-projects-description text-left">
              <h6 className="text-primary mb-0 text-uppercase">
                {imageDescription[key].type}
              </h6>
              <h3 className="text-white"> {imageDescription[key].title}</h3>
            </div>
          </div>
        </Link>
      </div>
    )
    galleryItemsSM.push(
      <Link key={key} to={imageDescription[key].url}>
        <div className="position-relative">
          <GatsbyImage
            image={imageData[key].childImageSharp.gatsbyImageData}
            alt={imageDescription[key].title}
            style={{
              borderRadius: "7px",
            }}
          />

          <div className="slider-case-description text-left">
            <h6 className="text-primary mb-0 text-uppercase">
              {imageDescription[key].type}
            </h6>
            <h3 className="text-white"> {imageDescription[key].title}</h3>
          </div>
        </div>
      </Link>
    )
  }

  return (
    <>
      <div className="pb-5 mx-4 d-md-none">
        <Slider {...slickSettings}>{galleryItemsSM}</Slider>
      </div>
      <div className="row d-none d-md-flex">{galleryItemsMD}</div>
    </>
  )
}

export default GalleryList
