import React from "react"
import { StaticQuery, graphql } from "gatsby"

import GalleryList from "./GalleryList"

const MobileApps = () => {
  const imageDescription = {
    mobileApp1: {
      title: "Foodel",
      type: "Mobile Application",
      url: "/work/mobile-applications/foodel",
    },
    mobileApp2: {
      title: "Amori",
      type: "Mobile Application",
      url: "/work/mobile-applications/amori",
    },
    mobileApp3: {
      title: "Wallet Optimizer",
      type: "Mobile Application",
      url: "/work/mobile-applications/wallet-optimizer",
    },
  }

  return (
    <StaticQuery
      query={graphql`fragment fluidImage on File {
  childImageSharp {
    gatsbyImageData(width: 600, layout: CONSTRAINED)
  }
}

{
  mobileApp1: file(relativePath: {eq: "work/mobile/foodel/1-landing-page.jpg"}) {
    ...fluidImage
  }
  mobileApp2: file(relativePath: {eq: "work/mobile/amori/login-wide.jpg"}) {
    ...fluidImage
  }
  mobileApp3: file(
    relativePath: {eq: "work/mobile/wallet-optimizer/splash-screen-wide.png"}
  ) {
    ...fluidImage
  }
}
`}
      render={data => (
        <GalleryList imageData={data} imageDescription={imageDescription} />
      )}
    />
  );
}

export default MobileApps
